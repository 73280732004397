/* Center-align content in the "About Us" section with background color and hover effect */
.about-us {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9; /* Add your default background color here */
  padding: 2rem; /* Add padding for spacing */
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
  transition: background-color 0.3s, transform 0.3s; /* Add transitions for smooth hover effect */
}

/* Apply hover effect on hover */
.about-us:hover {
  background-color: #8dbdf09f; /* Change the background color on hover */
  transform: scale(1.05); /* Enlarge on hover */
}

/* Center-align section title */
.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

/* Center-align section description */
.section-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}
/* Our Team Section Styles */
.our-team {
  background-color: #f9f9f9; /* Default background color */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 2rem 0;
  text-align: center; /* Center-align content */
  transition: background-color 0.3s; /* Add transition for hover effect */
}

/* Apply hover effect on hover */
.our-team:hover {
  background-color: #46a1baab; /* Change the background color on hover */
}

/* Team Members Container Styles */
.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align team members horizontally */
}

/* Team Member Styles */
.team-member {
  width: 70%;
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transitions for hover effect */
}

/* Apply hover effect on team member */
.team-member:hover {
  transform: scale(1.05); /* Enlarge on hover */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Add a shadow effect on hover */
}

/* Team Member Image Styles */
.team-member-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Team Member Name Styles */
.team-member-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #510404;
  margin-top: 1rem;
}

/* Team Member Role Styles */
.team-member-role {
  font-size: 1.2rem;
  color: #007bff;
}

/* Team Member Description Styles */
.team-member-description {
  font-size: 1rem;
  color: #666;
  margin-top: 1rem;
}
/* Our Values Section Styles */
.our-values {
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center; /* Center the content */
}

.values-list {
  list-style-type: none;
  padding-left: 0;
}

.value-item {
  margin-bottom: 1rem;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  background-color: #fff; /* Default background color */
  color: #333; /* Default text color */
}

.value-item:hover {
  background-color: #007bffa3; /* Background color on hover */
  color: #fff; /* Text color on hover */
  border-color: #007bff; /* Border color on hover */
}

.value-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #007bff3a;
  /* width: 20%; */
}

.value-description {
  font-size: 1.2rem;
}

/* Why We Trust You Section Styles */
.trust-values {
  list-style-type: disc;
  margin-left: 2rem;
  margin-top: 1rem;
  background-color: #007bff3a;
}

/* Additional Values Section Styles */
.trust-values {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  font-size: 1.2rem;
  color: #333;
  display: flex;
  flex-direction: column;
  background-color: #007bff3a;
}

.trust-values li::before {
  content: "\2022"; /* Use a bullet character as the list item marker */
  color: #007bffa6; /* Color of the bullet */
  font-size: 1.2rem;
  margin-right: 0.5em; /* Space between bullet and text */
}
/* Section Title Styles */
.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  background-color: #007bff77;
}

/* Section Description Styles */
.section-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}
/* Contact Us Section Styles */
.contact-us {
  background-color: #007bffa4;
  color: #fff;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
}

/* Contact Us Link Styles */
.contact-us a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.contact-us a:hover {
  text-decoration: underline;
}
