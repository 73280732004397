/* styles/evehicle.css */

/* Global Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  /* Header Styles */
  .header {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .header h1 {
    margin: 0;
  }
  
  /* Main Styles */
  .main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Banner Styles */
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .banner img {
    max-width: 100%;
    height: auto;
  }
  
  .banner-content {
    margin-top: 20px;
  }
  
  /* Button Styles */
  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  /* Services Styles */
  .services {
    margin-bottom: 40px;
  }
  
  .services h2 {
    margin-bottom: 20px;
  }
  
  .services ul {
    list-style-type: none;
    padding: 0;
  }
  
  /* How It Works Styles */
  .how-it-works {
    margin-bottom: 40px;
  }
  
  .how-it-works h2 {
    margin-bottom: 20px;
  }
  
  .how-it-works ol {
    padding-left: 20px;
  }
  
  /* Additional Content Styles */
  .additional-content {
    margin-bottom: 40px;
  }
  
  .additional-content h2 {
    margin-bottom: 20px;
  }
  
  /* Footer Styles */
  .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  