/* Reset some default styles */
body, p, h1, h2, h3 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Global Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
}

/* Container Styles */
.services-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
}

/* Title Styles */
.services-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
}

/* Description Styles */
.services-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #777;
}

/* Service Card Styles */
.service-card {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}

/* Service Title Styles */
.service-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
}

/* Service Description Styles */
.service-description {
    font-size: 1.1rem;
    color: #777;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .services-container {
        padding: 1rem;
    }

    .services-title {
        font-size: 2rem;
    }

    .services-description {
        font-size: 1rem;
    }

    .service-card {
        padding: 1rem;
    }

    .service-title {
        font-size: 1.5rem;
    }

    .service-description {
        font-size: 0.9rem;
    }
}
