/* Add this import at the top of your CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Style for the Device Selection Section */
.device-selection-section {
  text-decoration: none; /* Remove underlines from links */
  color: #cb2323; /* Text color for device names */
  transition: transform 0.2s ease; /* Add a subtle hover effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #9a3d3d; /* Add a border around the device links */
  padding: 20px; /* Add padding to create space around the content */
  background-color: #dbd9d9b7; /* Add a background color to the device links */
  border-radius: 10px; /* Add rounded corners to the border */
}
.slick-next {
  right: 0px;
}
/* Style for the Device Links */
.device-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Horizontally center-align items */
  gap: 20px; /* Gap between device links */
}

/* Style for Individual Device Links */
.device-links a {
  text-decoration: none; /* Remove underlines from links */
  color: #333; /* Text color for device names */
  transition: transform 0.2s ease; /* Add a subtle hover effect */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-links a:hover {
  transform: scale(1.1); /* Enlarge the device link on hover */
}

/* Style for Device Icons */
.device-links img {
  max-width: 100px; /* Set the maximum width for the device icons */
  max-height: 100px; /* Set the maximum height for the device icons */
}

/* Style for Device Names */
.device-links p {
  margin-top: 10px; /* Spacing between the icon and device name */
  font-size: 16px; /* Font size for device names */
}
@media screen and (min-width: 768px) {
  .device-links a {
    width: auto; /* Reset width to auto on larger screens */
  }
}
/* Our Services Section */
.service-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 30px 0;
}

.service-option {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.service-option:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-option img {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 10px;
}

.service-option p {
  font-size: 16px;
  font-weight: 600;
}

/* Media Query for Larger Screens */
@media screen and (min-width: 768px) {
  .service-options {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* Center-align "Our Services" text */
.service-heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: 600; /* Adjust the font weight as needed */
}
/* Brand Names and Images Slider */
/* Image Styling */
img {
  max-width: 100%; /* Ensure images are responsive and adapt to screen size */
  height: auto; /* Maintain aspect ratio */
  border: 2px solid #333; /* Add a border to images */
  border-radius: 10px; /* Round the corners of images */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

/* Image Alignment */
.center-image {
  display: block;
  margin: 0 auto; /* Center-align images */
}

.float-left {
  float: left; /* Float images to the left */
  margin-right: 20px; /* Add some spacing between floated images */
}

.float-right {
  float: right; /* Float images to the right */
  margin-left: 20px; /* Add some spacing between floated images */
}

/* Image Hover Effects */
img:hover {
  opacity: 0.8; /* Reduce opacity on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
  border-color: #ff5722; /* Change border color on hover */
}

/* Image Captions */
.image-caption {
  text-align: center; /* Center-align captions */
  font-size: 16px; /* Set font size for captions */
  color: #666; /* Caption text color */
  margin-top: 10px; /* Add spacing above captions */
}
/* Brand Names and Images Slider */
.brand-names-slider {
  text-align: center;
  padding: 50px 0;
}

.brand-names-slider h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.slick-slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.brand-item img {
  max-width: 80px; /* Adjust the maximum width for smaller logos */
  max-height: 80px; /* Adjust the maximum height for smaller logos */
  margin-bottom: 10px;
}


/* Hero Section */
.hero-section {
  background-image: url('../images/back.jpg'); /* Replace 'banner-image.jpg' with the actual path to your banner image */
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff; /* Text color */
  padding: 100px 0;
}

.hero-section img {
  max-width: 100%; /* Make sure the image stays within the container */
  height: auto; /* Allow the image to scale proportionally */
}

.hero-heading {
  font-size: 36px;
  font-weight: 700;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Background color for text */
  padding: 10px; /* Add some padding for better visibility */
  border-radius: 5px; /* Rounded corners for the background */
}

.hero-section p {
  font-size: 18px;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.87); /* Background color for text */
  padding: 10px; /* Add some padding for better visibility */
  border-radius: 5px; /* Rounded corners for the background */
}

.cta-link {
  background-color: #ff4500;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.cta-link:hover {
  background-color: #ff5722;
  text-decoration: none;
}
/* CTA Links */
.cta-link {
  background-color: #ff4500;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  margin-right: 10px; /* Add some margin to separate the links */
}

.cta-link:hover {
  background-color: #ff5722;
  text-decoration: none;
}
.introduction-section p {
  font-size: 18px; /* Adjust the font size as needed */
  line-height: 1.5; /* Adjust the line height for better readability */
  text-align: center; /* Center-align the text */
  margin: 20px auto; /* Add some margin for spacing */
  padding: 0 20px; /* Add padding to the sides for better text separation */
}


/* Appointment Booking Section */
.appointment-booking-section {
  text-align: center;
  padding: 50px 0;
}

.cta-text {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  background-color: #ff4500; /* Add background color for better visibility */
  color: #fff; /* Text color for contrast */
  padding: 10px 20px; /* Adjust padding for better spacing */
  border-radius: 5px; /* Add some rounding to the corners */
  display: inline-block; /* Ensure inline block for proper spacing */
}

.cta-link {
  background-color: #ff4500;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-align: center; /* Center text within the button */
}

.cta-link:hover {
  background-color: #ff5722;
}

/* Style the list for appointment steps */
.appointment-booking-section ol {
  list-style-type: decimal;
  text-align: left;
  margin: 20px 0;
  padding-left: 30px; /* Add some left padding for better alignment */
}

.appointment-booking-section li {
  font-size: 18px;
  margin-bottom: 10px;
}
/* Appointment Booking Section */
.appointment-booking-section {
  text-align: center;
  padding: 50px 0;
  max-width: 800px; /* Set a maximum width to center-align content on larger screens */
  margin: 0 auto; /* Center-align the section */
}

.cta-text {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  background-color: #ff4500;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
}

.cta-link {
  background-color: #ff4500;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-align: center;
}

.cta-link:hover {
  background-color: #ff5722;
}

/* Style the list for appointment steps */
.appointment-booking-section ol {
  list-style-type: decimal;
  text-align: left;
  margin: 20px 0;
  padding-left: 30px;
}

.appointment-booking-section li {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Responsive adjustments for larger screens */
@media screen and (min-width: 1200px) {
  .appointment-booking-section {
    padding: 80px 0; /* Increase padding for larger screens */
  }

  .cta-link {
    font-size: 24px; /* Increase font size for larger screens */
    padding: 20px 40px; /* Increase padding for larger screens */
  }
}
/* Style the introductory paragraph */
.appointment-booking-section p {
  background-color: #f5f5f5; /* Add a light gray background */
  padding: 20px; /* Add padding for better readability */
  border-radius: 5px; /* Round corners */
  color: #333; /* Text color */
  font-size: 18px;
  margin: 20px 0;
}

/* Style the list for appointment steps */
.appointment-booking-section ol {
  list-style-type: decimal;
  text-align: left;
  margin: 20px 0;
  padding-left: 30px;
}

.appointment-booking-section li {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Add hover effect for the "Book an Appointment" button */
.cta-link:hover {
  background-color: #ff5722; /* Change background color on hover */
  text-decoration: none;
  color: #fff; /* Change text color on hover */
  transition: background-color 0.3s ease;
}

/* Responsive adjustments for larger screens */
@media screen and (min-width: 1200px) {
  .appointment-booking-section {
    padding: 80px 0; /* Increase padding for larger screens */
  }

  .cta-link {
    font-size: 24px; /* Increase font size for larger screens */
    padding: 20px 40px; /* Increase padding for larger screens */
  }
}
/* Style the FAQ section */
.faq-section {
  padding: 20px;
  background-color: #f5f5f5; /* Add a light gray background */
  border-radius: 5px; /* Round corners */
}

/* Style the FAQ section title */
.faq-section h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333; /* Text color */
}

/* Style the FAQ toggle buttons */
.faq-toggle {
  color: #ff4500; /* Button text color */
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
  transition: transform 0.3s ease; /* Add a transition for the "+" sign */
}

/* Style the expanded FAQ toggle button */
.faq-toggle.expanded {
  transform: rotate(45deg); /* Rotate the "+" to create a "close" effect */
}

/* Style the FAQ items */
.faq-section ul {
  list-style: none;
  padding: 0;
}

.faq-section li {
  margin-bottom: 20px;
  font-size: 18px;
  display: flex; /* Align question and "+" sign horizontally */
  align-items: center; /* Vertically center items */
}

/* Style the FAQ question */
.faq-section strong {
  font-weight: 600;
  font-size: 20px; /* Increase font size for better visibility */
  margin-right: 10px; /* Add space between question and "+" sign */
  color: #333; /* Text color */
}

/* Style the FAQ answer */
.faq-section p {
  margin: 10px 0;
  font-size: 18px;
  color: #555; /* Text color */
}

/* Style the "Load More FAQs" button */
.load-more-button {
  background-color: #ff4500; /* Button background color */
  color: #fff; /* Button text color */
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  display: block;
  width: 100%; /* Expand to full width */
  text-align: center; /* Center text */
}

/* Change button background color on hover */
.load-more-button:hover {
  background-color: #ff5722;
  text-decoration: none;
}
/* Style the Trending Articles section */
.trending-articles-section {
  padding: 20px;
  background-color: #f9f9f9; /* Add a light background color */
  border-radius: 5px; /* Round corners */
}

/* Style the Trending Articles title */
.trending-articles-section h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333; /* Text color */
}

/* Style the Trending Articles list */
.trending-articles-section ul {
  list-style: none;
  padding: 0;
  display: flex; /* Display articles in a horizontal row */
  flex-wrap: wrap; /* Wrap to the next line if there's not enough space */
}

/* Style individual Trending Article items */
.trending-articles-section li {
  margin-right: 20px; /* Add space between articles */
  margin-bottom: 20px; /* Add space between rows */
  width: calc(33.33% - 20px); /* Show 3 articles per row (adjust as needed) */
}

/* Style the Trending Article image */
.trending-articles-section img {
  max-width: 100%; /* Ensure images don't exceed their container width */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra spacing below images */
  border-radius: 5px; /* Round corners of images */
}

/* Style the Trending Article title */
.trending-articles-section h3 {
  font-size: 18px;
  margin-top: 10px; /* Add space between image and title */
  color: #333; /* Text color */
}

/* Style the "Read More" link */
.read-more-link {
  display: block;
  margin-top: 10px; /* Add space between title and link */
  color: #ff4500; /* Link color */
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease; /* Add a color transition on hover */
}

/* Change link color on hover */
.read-more-link:hover {
  color: #ff5722;
}
/* Style the Trust and Assurance section */
.trust-assurance-section {
  padding: 50px 0;
  text-align: center;
  background-color: #f9f9f9; /* Add a light background color */
  border-radius: 5px; /* Round corners */
}

/* Style the certifications container */
.certifications {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Add space between certifications and text */
}

/* Style individual certification images */
.certifications img {
  max-width: 100px; /* Set the maximum width for certification images */
  max-height: 100px; /* Set the maximum height for certification images */
  margin: 0 20px; /* Add space between certification images */
  border-radius: 5px; /* Round corners of certification images */
}

/* Style the Trust and Assurance text */
.trust-assurance-section p {
  font-size: 18px;
  color: #333; /* Text color */
  margin: 0; /* Remove default margin */
}

/* Add padding to the top of the Trust and Assurance text */
.trust-assurance-section p:first-child {
  margin-top: 20px;
}

/* Style the horizontal line */
.trust-assurance-section hr {
  border: 0;
  border-top: 2px solid #ddd; /* Horizontal line style */
  margin: 20px auto; /* Add space above and below the line */
  width: 50%; /* Adjust the width of the line */
}
/* Styling for the "Sell & Buy Your Old Mobile Phone On RepairTechies" section */
.repair-techies-section {
  padding: 50px 0;
  text-align: center;
  background-color: #f5f5f5; /* Background color */
}

.repair-techies-section h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333; /* Text color */
}

.repair-techies-section h3 {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  color: #333; /* Text color */
}

.repair-techies-section p {
  font-size: 18px;
  line-height: 1.6;
  color: #555; /* Text color */
  margin-bottom: 20px;
}

/* Styling for strong elements within the section */
.repair-techies-section strong {
  font-weight: 700;
  color: #333; /* Text color */
}

/* Styling for bullet points */
.repair-techies-section ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

/* Styling for links */
.repair-techies-section a {
  color: #ff5722; /* Link color */
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.repair-techies-section a:hover {
  color: #ff4500; /* Link color on hover */
}

/* Add padding around images */
.repair-techies-section img {
  max-width: 100%;
  border-radius: 10px; /* Rounded corners for images */
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to images */
}
/* Center-align the entire section */
.repair-techies-section {
  padding: 50px 0;
  background-color: #f5f5f5;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Styling for the heading */
.repair-techies-section h2 {
  font-size: 28px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

/* Styling for the introductory description */
.repair-techies-section p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

/* Styling for links */
.repair-techies-section a {
  color: #ff5722;
  text-decoration: none;
  transition: color 0.3s ease;
}

.repair-techies-section a:hover {
  color: #ff4500;
}

/* Styling for subheadings */
.repair-techies-section h3 {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  color: #333;
}

/* Styling for strong text */
.repair-techies-section strong {
  font-weight: 700;
  color: #333;
}
