/* Page404.css */

.page-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f3f4f6;
  }
  
  .card {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .not-found-text {
    font-size: 4rem;
    font-weight: 600;
    color: #4f46e5;
  }
  
  .not-found-title {
    margin-top: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #1f2937;
  }
  
  .not-found-message {
    margin-top: 1.5rem;
    font-size: 1.25rem;
    color: #4b5563;
  }
  
  .not-found-button {
    margin-top: 2rem;
  }
  
  .go-home-button {
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    background-color: #4f46e5;
    color: white;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .go-home-button:hover {
    background-color: #4338ca;
  }
  
  .contact-support {
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .contact-support-link {
    color: #4f46e5;
    font-weight: bold;
    text-decoration: none;
  }
  