/* CSS for the footer */
footer {
    background-color: #333;
    color: #fff;
    padding: 30px 0;
  }
  
  footer div {
    text-align: center;
  }
  
  footer h1 {
    font-size: 24px;
    color: azure;
  }
  
  footer p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  footer ul {
    list-style: none;
    padding: 0;
  }
  
  footer li {
    display: inline-block;
    margin-right: 20px;
  }
  
  footer a {
    color: #fff;
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }
  
  footer .social-links li {
    margin-right: 10px;
  }
  
  footer .social-links a {
    font-size: 20px;
  }
  
  footer p.copyright {
    font-size: 12px;
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    footer {
      text-align: center;
    }
  
    footer div {
      margin-bottom: 30px;
    }
  
    footer ul {
      margin-bottom: 20px;
    }
  
    footer li {
      display: block;
      margin-bottom: 10px;
    }
  }
  