/* HindiBlog.css */

.news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .news-item {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .news-item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .news-item h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .news-item p {
    color: #555;
  }
  
  .news-item a {
    display: block;
    color: #0066cc;
    text-decoration: none;
    font-weight: bold;
  }
  